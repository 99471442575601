export const ChatEmojiIcon = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      fill='#000000'
      viewBox='0 0 14 14'
    >
      <path
        fill='#8B9294'
        fillRule='evenodd'
        d='M3.907 9.123a.678.678 0 11.903-1.012c1.226 1.093 3.154 1.093 4.38 0a.678.678 0 11.903 1.012A4.641 4.641 0 017 10.284a4.642 4.642 0 01-3.093-1.16zM0 7c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7-7-3.14-7-7zm1.356 0A5.651 5.651 0 007 12.644 5.651 5.651 0 0012.644 7 5.651 5.651 0 007 1.356 5.651 5.651 0 001.356 7zm9.437-1.25a1.151 1.151 0 11-2.303 0 1.151 1.151 0 012.303 0zm-5.283 0a1.151 1.151 0 11-2.303 0 1.151 1.151 0 012.303 0z'
        clipRule='evenodd'
      />
    </svg>
  );
};
